import { Fragment } from "react";
import { CustomCreatableSelect } from "../../../global/creatable_select";

export const departmentsWidget = (
  history,
  company,
  selectedDepartment,
  setSelectedDepartment,
  setAlertAction,
  loadCompaniesAction,
  addDepartmentAction,
  deleteDepartmentAction,
  setCompany
) => {
  const onAddDepartment = async (department_name) => {
    try {
      const res = await addDepartmentAction(company.id, department_name);
      //   history.push("/admin/companies/edit", res);
      setCompany(res);
    } catch (err) {
      console.log(err);
      setAlertAction("Abteilung konnte nicht hinzugefügt werden", "danger");
    }
  };

  const onDeleteDepartment = async () => {
    try {
      //   const config = { headers: { "Content-Type": "application/json" } };
      //   const body = { company_id: company.id, department_name: selectedDepartment };
      //   const res = await axios.post(basePath + "/api/company/delete-department", body, config);
      //   const res = await axios.post(basePath + "/api/company/delete-department", body, config);
      const res = await deleteDepartmentAction(company.id, selectedDepartment);
      //   history.push("/admin/companies/edit", res.data.data);
      setCompany(res);
    } catch (err) {
      console.log(err);
      setAlertAction("Abteilung konnte nicht gelöscht werden", "danger");
    }
  };

  return (
    <Fragment>
      <h1>ABTEILUNGEN BEARBEITEN</h1>
      <p className="text-bold">Bitte verwalten Sie hier die Abteilungen der Firma {company.name} </p>

      {CustomCreatableSelect({
        classNameForDiv: "",
        placeholder: "Abteilungen",
        options: company.data.departments.map((department) => ({
          value: department,
          label: department,
          isFixed: true,
        })),
        // options: [{ value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true }],
        onCreate: (department_name) => onAddDepartment(department_name),
        onSelect: (department_name) => setSelectedDepartment(department_name),
      })}
      <button className="button-danger right50 my" onClick={onDeleteDepartment}>
        Abteilung löschen
      </button>
    </Fragment>
  );
};
