export function formatToDate(dateTime) {
  //   if (dateTime != undefined) {
  const onlyDate = dateTime.split("T")[0];
  const parts = onlyDate.split("-");

  let concatenatedString = "";
  parts.reverse().forEach((part) => {
    concatenatedString += part;
    concatenatedString += ".";
  });
  const finalString = concatenatedString.slice(0, -1);
  return finalString;
  //   } else {
  // return "";
  //   }
}
