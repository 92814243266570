import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { app } from "../../../App";
// import { loadSingleOrderAction } from "../../redux/actions_reducers/orders/order_actions";
import { Loader } from "../loader";
import { singleOrderPage } from "./sub_components/single_order_page";

function UserSingleOrder(props) {
  const { company } = props;

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const anummer = props.location.state;
  const stop = true;

  useEffect(async () => {
    setLoading(true);
    // app.functions("europe-west3").useEmulator("localhost", 5001);
    const res = await app.functions("europe-west3").httpsCallable("app-api")({
      function_name: "data-get_single_order",
      company_sql_id: company.data.sql_id,
      anummer,
    });
    console.log(res.data);
    const _order = JSON.parse(res.data.data)[0];
    setOrder(_order);
    setLoading(false);
  }, [stop]);

  if (company.data.sql_access === true) {
    if (loading) {
      return (
        <Fragment>
          <div className="container">
            <h1 className="large">Auftrag: {anummer}</h1>
            {Loader()}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="container">
            <h1 className="large">Auftrag: {anummer}</h1>
            {order != null && order != undefined ? (
              singleOrderPage(order, company)
            ) : (
              <h3>
                Leider wurde kein Auftrag mit der Nummer '{anummer}' gefunden. Bitte prüfen Sie nochmals die
                Auftragsnummer
              </h3>
            )}
          </div>
        </Fragment>
      );
    }
  } else return <Fragment></Fragment>;
}

UserSingleOrder.propTypes = {
  //   loadOrdersAction: PropTypes.func.isRequired,
};

const extractPropsFromStore = (state) => ({
  company: state.authReducer.company,
  //   loading: state.ordersReducer.loading,
  //   orders: state.ordersReducer.orders,
  //   ordersResult: state.ordersReducer.ordersResult,
});

export default connect(extractPropsFromStore)(UserSingleOrder);
