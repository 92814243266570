export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <input
      className="p table-input"
      placeholder="Filtern"
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
    ></input>
  );
};
