import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import img from "../../../assets/logo.png";
import { loginAction } from "../../redux/actions_reducers/auth/auth_actions";
import { LargeLoader } from "../loader";

function Login(props) {
  const history = useHistory();
  // Handling the state that contains form-data
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  }); // make data available to complete component
  const { email, password } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  // Handling form-submit
  const onSubmit = async (e) => {
    e.preventDefault();
    props.loginAction(email, password);
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // Redirect if logged in
  if (props.loading) {
    return <Fragment>{LargeLoader()}</Fragment>;
  }
  if (props.isAuthenticated) {
    if (props.role === "admin") {
      return <Redirect to="/admin/companies" />;
    } else {
      console.log(props?.company?.data?.sql_access);
      if (props?.company?.data?.sql_access) {
        return <Redirect to="/user/dashboard" />;
      }
      return <Redirect to="/user/documents" />;
    }
  }
  return (
    <Fragment>
      <section className="container">
        <div>
          <img className="img-center" src={img} alt="test"></img>
        </div>
        <h1 className="large text-center">Login</h1>
        <form className="form m-2" onSubmit={onSubmit}>
          <div className="">E-MAIL</div>
          <div className="form-group">
            <input type="email" name="email" value={email} onChange={(e) => onChange(e)} required />
          </div>
          <div className="">PASSWORT</div>
          <div className="form-group">
            <div className="icon-input-container">
              <input
                className="icon-input-field"
                name="password"
                type={passwordShown ? "text" : "password"}
                value={password}
                onChange={(e) => onChange(e)}
              ></input>
              <i
                className={passwordShown ? "fa fa-eye-slash input-icon" : "fa fa-eye input-icon"}
                onClick={togglePasswordVisiblity}
              ></i>
            </div>
            <div className="fr link" onClick={() => history.push(`request-password-reset?email=${email}`, true)}>
              Passwort vergessen?
            </div>
          </div>
          <br></br>

          <input type="submit" className="light-grey button-light" value="EINLOGGEN" />
        </form>
      </section>
    </Fragment>
  );
}

Login.propTypes = {
  loginAction: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  role: PropTypes.string,
  company: PropTypes.object,
};

const extractPropsFromStore = (state) => ({
  loading: state.authReducer.loading,
  isAuthenticated: state.authReducer.isAuthenticated,
  role: state.authReducer.role,
  company: state.authReducer.company,
});

// null means that no properties are extracted from the redux-store
export default connect(extractPropsFromStore, { loginAction })(Login);
