import { PropTypes } from "prop-types";
import { Fragment, useMemo, useState, memo } from "react";
import { connect } from "react-redux";

import { useFilters, useTable } from "react-table";
import { loadOrdersAction } from "../../redux/actions_reducers/orders/order_actions";
import { Loader } from "../loader";
import { Columns } from "./sub_components/columns";
import { Table } from "./sub_components/table";
import { SingleOrderSearch } from "./sub_components/SingleOrderSearch";
import { parseErgpms } from "../../utils/parse_ergpms";

const UserDashboard = memo(function UserDashboard(props) {
  const { loading, orders, ordersResult, loadOrdersAction, company } = props;

  const columns = useMemo(() => Columns(company), []);
  const Data = orders.map((order) => ({
    ...order,
    ergpdf: { ergpdf: order.ergpdf, name: "MelutecAuftragNr" + order.anummer + ".pdf" },
    ergzip: { ergzip: order.ergzip, name: "MelutecAuftragNr" + order.anummer + ".zip", eintrag: order.eintrag },
    status: order.status === 2 ? "fertig" : "offen",
    ergpms: parseErgpms(order.ergpms),
  }));
  const data = useMemo(() => Data, [orders]);

  const tableInstance = useTable(
    // useGlobalFilter
    {
      columns,
      data,
    },
    useFilters
  );

  if (company.data.sql_access === true) {
    if (loading) {
      return (
        <Fragment>
          <div className="container">
            <h1 className="large">ÜBERSICHT</h1>
            {Loader()}
          </div>
        </Fragment>
      );
    } else if (!loading && ordersResult === "success") {
      return (
        <Fragment>
          <div className="flex">
            <div className="custom-container-1">
              <h1 className="large">ÜBERSICHT</h1>
              <p className="text-bold">Internetportal zur Übersicht der Aufträge von {company.data.name} an MELUTEC</p>
            </div>
            <div className="mt-12 mr-4">
              <SingleOrderSearch></SingleOrderSearch>
            </div>
          </div>

          {Table(tableInstance)}
          <button
            className="dark-grey button-dark w80"
            onClick={() => loadOrdersAction(orders.length, company.id, company.data.sql_access)}
          >
            Mehr Aufträge laden
          </button>
        </Fragment>
      );
    } else {
      return <div className="container"></div>;
    }
  } else {
    return (
      <div className="container">
        <h1 className="large">Kein Dashboard angelegt</h1>
        <p>
          Es wurde von Melutec kein Dashboard für Sie angelegt. Sollte das ein Fehler sein, wenden Sie sich bitte an
          uns.
        </p>
      </div>
    );
  }
});

UserDashboard.propTypes = {
  loadOrdersAction: PropTypes.func.isRequired,
};

const extractPropsFromStore = (state) => ({
  company: state.authReducer.company,
  loading: state.ordersReducer.loading,
  orders: state.ordersReducer.orders,
  ordersResult: state.ordersReducer.ordersResult,
});

export default connect(extractPropsFromStore, { loadOrdersAction })(UserDashboard);
