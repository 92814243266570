import "./App.css";
import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Firebase
import firebase from "firebase/app";
import { firebaseConfig } from "./lib/utils/firebase_config";

// Redux
import { Provider } from "react-redux";
import reduxStore from "./lib/redux/store";
import { loadUserAction } from "./lib/redux/actions_reducers/auth/auth_actions";
//

// Components
import Navbar from "./lib/components/Navbar";
import Alert from "./lib/components/Alert";

import Login from "./lib/components/auth/Login";
import PrivateRoute from "./lib/components/auth/PrivateRoute";

import AdminRoute from "./lib/components/auth/AdminRoute";
// import AdminDashboard from "./lib/components/admin/AdminDashboard";
import Documents from "./lib/components/admin/documents/Documents";
import DeleteDocument from "./lib/components/admin/documents/DeleteDocument";
import Companies from "./lib/components/admin/companies/Companies";
import CreateCompany from "./lib/components/admin/companies/CreateCompany";
import EditCompany from "./lib/components/admin/companies/EditCompany/EditCompany";
import UploadCSV from "./lib/components/admin/companies/UploadCSV";

// import FileUpload from "./lib/components/admin/FileUpload";

import UserDashboard from "./lib/components/user/UserDashboard";
import UserSingleOrder from "./lib/components/user/SingleOrder";
import UserDocuments from "./lib/components/user/UserDocuments";

import ForgotPasswordPage from "./lib/components/auth/Password/forgot_password";
import PasswordResetPage from "./lib/components/auth/Password/password_reset";
import RequestPasswordResetPage from "./lib/components/auth/Password/request_password_reset";
import ChangeSQL from "./lib/components/admin/companies/ChangeSQL";

// import CreateBatch from "./lib/components/admin/CreateBatch";

export const app = firebase.initializeApp(firebaseConfig);

function App() {
  useEffect(() => {
    // see docs to fully understand this
    reduxStore.dispatch(loadUserAction());
  }, []);

  return (
    <Provider store={reduxStore}>
      <Router>
        <Fragment>
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/reset-password" component={PasswordResetPage} />
          <Route exact path="/request-password-reset" component={RequestPasswordResetPage} />
        </Fragment>
        <Fragment>
          <Navbar />
          <Route exact path="/" component={Login} />
          <Switch>
            <PrivateRoute exact path="/user/dashboard" component={UserDashboard} />
            <PrivateRoute exact path="/user/single" component={UserSingleOrder} />
            <PrivateRoute exact path="/user/documents" component={UserDocuments} />
          </Switch>
          <section className="container">
            <Switch>
              {/* <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} /> */}
              <AdminRoute exact path="/admin/documents" component={Documents} />
              <AdminRoute exact path="/admin/documents/delete" component={DeleteDocument} />
              <AdminRoute exact path="/admin/companies" component={Companies} />
              <AdminRoute exact path="/admin/companies/new" component={CreateCompany} />
              <AdminRoute exact path="/admin/companies/edit" component={EditCompany} />
              <AdminRoute exact path="/admin/companies/edit/batch" component={UploadCSV} />
              <AdminRoute exact path="/admin/companies/edit/sql" component={ChangeSQL} />
            </Switch>
            <Alert />
          </section>
        </Fragment>
      </Router>
    </Provider>
  );
}

export default App;
