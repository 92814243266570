import { Fragment } from "react";
import { CustomSelect } from "../../../global/select";

export const newContactForm = (
  first_name,
  last_name,
  department,
  email,
  onAddContactFormChange,
  onSubmitAddContact,
  company
) => (
  <Fragment>
    <h3>Kontakt manuell hinzufügen: </h3>
    <form className="form">
      <div className="flex">
        <div className="auto-flex my mr">
          <input
            type="text"
            placeholder="Vorname"
            name="first_name"
            value={first_name}
            onChange={onAddContactFormChange}
            required
          />
        </div>
        <div className="auto-flex my ml ">
          <input
            type="text"
            placeholder="Nachname"
            name="last_name"
            value={last_name}
            onChange={onAddContactFormChange}
            required
          />
        </div>
      </div>
      {CustomSelect({
        placeholder: "Abteilung",
        options: company.data.departments.map((department) => ({
          value: department,
          label: department,
          isFixed: true,
        })),
        onChange: (data) => onAddContactFormChange({ target: { name: "department", value: data?.value } }),
      })}
      {/* <input
        type="text"
        placeholder="Abteilung"
        name="department"
        value={department}
        onChange={onAddContactFormChange}
        required
      /> */}
      <input
        type="email"
        placeholder="E-Mail"
        name="email"
        value={email}
        onChange={onAddContactFormChange}
        className="my"
        required
      />
      <input
        type="submit"
        value="hinzufügen"
        className="dark-grey button-dark right50"
        onClick={(e) => onSubmitAddContact(e)}
      />
    </form>
  </Fragment>
);
