import {
  CONTACTS_LOADING_REQUESTED,
  CONTACTS_LOADING_SUCCEEDED,
  CONTACTS_LOADING_FAILED,
  CONTACT_DELETE_REQUESTED,
  CONTACT_DELETE_FAILED,
  CONTACT_CREATE_FAILED,
  CONTACT_CREATE_REQUESTED,
  CONTACT_CREATE_SUCCEEDED,
  CONTACT_DELETE_SUCCEEDED,
} from "../action_types";

const initialState = {
  contactsLoading: false,
  contacts: null,
  contactsResult: null,
};

export const contactsReducer = function (state = initialState, givenAction) {
  const { type, payload } = givenAction;

  switch (type) {
    case CONTACT_DELETE_REQUESTED:
    case CONTACT_CREATE_REQUESTED:
    case CONTACTS_LOADING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CONTACTS_LOADING_SUCCEEDED:
    case CONTACT_CREATE_SUCCEEDED:
    case CONTACT_DELETE_SUCCEEDED:
      return {
        loading: false,
        contacts: payload.data,
        contactsResult: "success",
      };

    case CONTACT_DELETE_FAILED:
    case CONTACT_CREATE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case CONTACTS_LOADING_FAILED:
      return {
        loading: false,
        contacts: [],
        contactsResult: "failure",
      };
    default:
      return state;
  }
};
