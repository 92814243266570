import { Fragment, memo, useState } from "react";
import { useHistory } from "react-router-dom";

export const SingleOrderSearch = memo(function SingleOrderSearch(props) {
  const history = useHistory();

  const [anummer, setAnummer] = useState("");
  const onSingleOrderFormSubmit = (event) => {
    event.preventDefault();
    history.push({ pathname: "/user/single", state: anummer.trim() });
  };

  return (
    <Fragment>
      <h3>Einen bestimmten Auftrag suchen</h3>
      <form onSubmit={onSingleOrderFormSubmit}>
        <input
          placeholder="Auftragsnummer"
          value={anummer}
          onChange={(e) => setAnummer(e.target.value)}
          type="text"
        ></input>
      </form>
    </Fragment>
  );
});
