import { Fragment } from "react";
import { CustomMultiSelect } from "../../../global/multi_select";

export const contactsWidget = (
  company,
  contacts,
  deleteContactsFormData,
  setDeleteContactsFormData,
  deleteContactsAction
) => {
  //

  const onDeleteContactsFormChange = (e) => setDeleteContactsFormData(e);
  const onSubmitDeleteContacts = (e) => {
    e.preventDefault();
    const ids = deleteContactsFormData.map((doc) => doc.contact.id);
    deleteContactsAction(ids, company.id);
  };

  //
  const parseContactsToSelect = (contacts) =>
    contacts.map((contact) => {
      return {
        value: `${contact.data.first_name}_${contact.data.last_name}`,
        label: contact.data.first_name + " " + contact.data.last_name,
        contact: contact,
      };
    });

  return (
    <Fragment>
      <div className="mt-4">
        <h1 className="">KONTAKTE BEARBEITEN </h1>
        <p className="text-bold">Bitte verwalten Sie hier die Kontakte der Firma {company.name} </p>
      </div>

      <div className="">
        {CustomMultiSelect({
          classNameForDiv: "pt-1 pb",
          options: parseContactsToSelect(contacts),
          placeholder: "Liste aller bestehenden Kontakte",
          onChange: onDeleteContactsFormChange,
        })}
        <button className="button-danger right50" onClick={onSubmitDeleteContacts}>
          Auswahl löschen
        </button>
      </div>
    </Fragment>
  );
};
