import { PropTypes } from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAlertAction } from "../../../../redux/actions_reducers/alerts/alert_actions";
import {
  addDepartmentAction,
  deleteDepartmentAction,
  loadCompaniesAction,
} from "../../../../redux/actions_reducers/companies/company_actions";
import {
  addContactAction,
  deleteContactsAction,
  loadContactsAction,
} from "../../../../redux/actions_reducers/contacts/contacts_actions";
import setAuthToken from "../../../../utils/set_auth_token";
import { Loader } from "../../../loader";
import { contactsWidget } from "./contacts";
import { csvWidget } from "./csv";
import { departmentsWidget } from "./departments";
import { newContactForm } from "./new_contact_form";

function EditContacts(props) {
  const {
    contactsLoading,
    contacts,
    contactsResult,
    companiesLoading,
    loadContactsAction,
    deleteContactsAction,
    addContactAction,
    setAlertAction,
    loadCompaniesAction,
    addDepartmentAction,
    deleteDepartmentAction,
  } = props;
  const history = useHistory();
  const [company, setCompany] = useState(props.location.state);

  useEffect(() => {
    loadContactsAction(company.id);
    // loadCompaniesAction();
  }, [loadContactsAction, loadCompaniesAction, company]);

  // Handling the state that contains form-data
  const [addContactFormData, setAddContactFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    department: "",
  }); // make data available to complete component
  const { first_name, last_name, email, department } = addContactFormData;
  const onAddContactFormChange = (e) =>
    setAddContactFormData({ ...addContactFormData, [e.target.name]: e.target.value.toString().trim() });
  const onSubmitAddContact = (e) => {
    e.preventDefault();
    addContactAction(first_name, last_name, email, company.id, department);
    setAddContactFormData({ first_name: "", last_name: "", email: "", department: "" });
  };

  //
  const [deleteContactsFormData, setDeleteContactsFormData] = useState([]);

  //
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  if (companiesLoading || contactsLoading) {
    return (
      <Fragment>
        <div>
          <h1 className="large text-primary">KUNDEN BEARBEITEN: {company.data.name}</h1>
        </div>
        {Loader()}
      </Fragment>
    );
  } else if (!contactsLoading && contactsResult === "success") {
    // setContacts(contacts);
    return (
      <Fragment>
        <h1 className="large0">KUNDEN BEARBEITEN: {company.data.name} </h1>
        {company.data.sql_id ? (
          <h4>
            Referenz in Ihrer MySQL-Datenbank:{" "}
            <div
              className="inline link"
              onClick={() => {
                history.push("/admin/companies/edit/sql", company);
              }}
            >
              {company.data.sql_id}
            </div>
          </h4>
        ) : (
          <Fragment></Fragment>
        )}
        <br></br>

        {departmentsWidget(
          history,
          company,
          selectedDepartment,
          setSelectedDepartment,
          setAlertAction,
          loadCompaniesAction,
          addDepartmentAction,
          deleteDepartmentAction,
          setCompany
        )}

        <div>
          {contactsWidget(company, contacts, deleteContactsFormData, setDeleteContactsFormData, deleteContactsAction)}

          <div className="my-5">
            {csvWidget(history, company)}
            <div className="my-1">
              {newContactForm(
                first_name,
                last_name,
                department,
                email,
                onAddContactFormChange,
                onSubmitAddContact,
                company
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div>
          <h1 className="large text-primary">KUNDEN BEARBEITEN</h1>
        </div>
        <div></div>
      </Fragment>
    );
  }
}

EditContacts.propTypes = {
  loadContactsAction: PropTypes.func.isRequired,
};

const extractPropsFromStore = (state) => ({
  contactsLoading: state.contactsReducer.loading,
  companiesLoading: state.companiesReducer.loading,
  contacts: state.contactsReducer.contacts,
  contactsResult: state.contactsReducer.contactsResult,
});

export default connect(extractPropsFromStore, {
  loadContactsAction,
  deleteContactsAction,
  addContactAction,
  setAlertAction,
  loadCompaniesAction,
  addDepartmentAction,
  deleteDepartmentAction,
})(EditContacts);
