import { ergzipDownload } from "../../../utils/ergzip_download";
import { ergpdfDownload } from "../../../utils/ergpdf_download";
import { renderErgpms } from "../../../utils/parse_ergpms";
import { ColumnFilter } from "./column_filter";

export const Columns = (company) => [
  {
    Header: "Auftrags Nr.",
    accessor: "anummer",
    Filter: ColumnFilter,
  },
  {
    Header: "Auftragsdatum",
    accessor: "adatum",
    Filter: ColumnFilter,
  },
  {
    Header: "Besteller",
    accessor: "besteller",
    Filter: ColumnFilter,
  },
  {
    Header: "Wareneingang",
    accessor: "wdatum",
    Filter: ColumnFilter,
  },
  {
    Header: "Liefertermin",
    accessor: "termin",
    Filter: ColumnFilter,
  },
  {
    Header: "Melutec Nr.",
    accessor: "mnummer",
    style: { width: "300px" },
    Filter: ColumnFilter,
  },

  {
    Header: "Gesamt",
    accessor: "gesamt",
    disableFilters: true,
    Filter: ColumnFilter,
  },
  {
    Header: "Fertig",
    accessor: "fertig",
    disableFilters: true,
    Filter: ColumnFilter,
  },
  {
    Header: "Prozent",
    accessor: "prozent",
    disableFilters: true,
    Filter: ColumnFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: ColumnFilter,
  },
  {
    Header: "PDF",
    accessor: "ergpdf",
    disableFilters: true,
    Filter: ColumnFilter,
    Cell: (doc) => ergpdfDownload(doc, true),
  },
  {
    Header: "ZIP",
    accessor: "ergzip",
    disableFilters: true,
    Filter: ColumnFilter,
    Cell: (doc) => ergzipDownload(doc, company, true),
  },
  {
    Header: "Ergebnis",
    accessor: "ergpms",
    disableFilters: true,
    Filter: ColumnFilter,
    Cell: (data) => renderErgpms(data, true),
  },
  {
    Header: "Kunden Nr.",
    accessor: "knummer",
    Filter: ColumnFilter,
  },
  {
    Header: "Name",
    accessor: "kname",
    Filter: ColumnFilter,
  },
  {
    Header: "PLZ",
    accessor: "kplz",
    Filter: ColumnFilter,
  },
  {
    Header: "Ort",
    accessor: "kort",
    Filter: ColumnFilter,
  },
];
