import { v4 as uuid } from "uuid";
export const Table = (instance) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = instance;

  const _filteredColumn = (column) => (
    <div className="flex-table">
      <div className="p mb-1">{column.render("Header")}</div>
      <div className="">{column.canFilter ? column.render("Filter") : null}</div>
    </div>
  );

  const _unfilteredColumn = (column) => {
    return <div className="text-center">{column.render("Header")}</div>;
  };

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((group) => (
          <tr {...group.getHeaderGroupProps()}>
            {group.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                {column.canFilter ? _filteredColumn(column) : _unfilteredColumn(column)}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td key={uuid()} {...cell.getCellProps}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
