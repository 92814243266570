export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

//
export const DOCUMENTS_LOADING_REQUESTED = "DOCUMENTS_LOADING_REQUESTED";
export const DOCUMENTS_LOADING_SUCCEEDED = "DOCUMENTS_LOADING_SUCCEEDED";
export const DOCUMENTS_LOADING_FAILED = "DOCUMENTS_LOAD_FAILED";
// export const DOCUMENTS_RESET = "ORDERS_RESET";

//
export const ORDERS_LOADING_REQUESTED = "ORDERS_LOADING_REQUESTED";
export const ORDERS_LOADING_SUCCEEDED = "ORDERS_LOADING_SUCCEEDED";
export const ORDERS_LOADING_FAILED = "ORDERS_LOAD_FAILED";
export const ORDERS_RESET = "ORDERS_RESET";

//
export const COMPANIES_LOADING_REQUESTED = "COMPANIES_LOADING_REQUESTED";
export const COMPANIES_LOADING_SUCCEEDED = "COMPANIES_LOADING_SUCCEEDED";
export const COMPANIES_LOADING_FAILED = "COMPANIES_LOAD_FAILED";

export const COMPANY_DELETE_REQUESTED = "COMPANY_DELETE_REQUESTED";
export const COMPANY_DELETE_SUCCEEDED = "COMPANY_DELETE_SUCCEEDED";
export const COMPANY_DELETE_FAILED = "COMPANY_DELETE_FAILED";

export const COMPANY_CREATE_REQUESTED = "COMPANY_CREATE_REQUESTED";
export const COMPANY_CREATE_SUCCEEDED = "COMPANY_CREATE_SUCCEEDED";
export const COMPANY_CREATE_FAILED = "COMPANY_CREATE_FAILED";

export const CHANGE_SQL_ID_REQUESTED = "CHANGE_SQL_ID_REQUESTED";
export const CHANGE_SQL_ID_SUCCEEDED = "CHANGE_SQL_ID_SUCCEEDED";
export const CHANGE_SQL_ID_FAILED = "CHANGE_SQL_ID_FAILED";

export const DEPARTMENT_CREATE_REQUESTED = "DEPARTMENT_CREATE_REQUESTED";
export const DEPARTMENT_CREATE_SUCCEEDED = "DEPARTMENT_CREATE_SUCCEEDED";
export const DEPARTMENT_CREATE_FAILED = "DEPARTMENT_CREATE_FAILED";
export const DEPARTMENT_DELETE_REQUESTED = "DEPARTMENT_DELETE_REQUESTED";
export const DEPARTMENT_DELETE_SUCCEEDED = "DEPARTMENT_DELETE_SUCCEEDED";
export const DEPARTMENT_DELETE_FAILED = "DEPARTMENT_DELETE_FAILED";

//
export const CONTACTS_LOADING_REQUESTED = "CONTACTS_LOADING_REQUESTED";
export const CONTACTS_LOADING_SUCCEEDED = "CONTACTS_LOADING_SUCCEEDED";
export const CONTACTS_LOADING_FAILED = "CONTACTS_LOADING_FAILED";

export const CONTACT_DELETE_REQUESTED = "CONTACT_DELETE_REQUESTED";
export const CONTACT_DELETE_SUCCEEDED = "CONTACT_DELETE_SUCCEEDED";
export const CONTACT_DELETE_FAILED = "CONTACT_DELETE_FAILED";

export const CONTACT_CREATE_REQUESTED = "CONTACT_CREATE_REQUESTED";
export const CONTACT_CREATE_SUCCEEDED = "CONTACT_CREATE_SUCCEEDED";
export const CONTACT_CREATE_FAILED = "CONTACT_CREATE_FAILED";

export const CONTACT_BATCH_CREATE_REQUESTED = "CONTACT_BATCH_CREATE_REQUESTED";
export const CONTACT_BATCH_CREATE_SUCCEEDED = "CONTACT_BATCH_CREATE_SUCCEEDED";
export const CONTACT_BATCH_CREATE_FAILED = "CONTACT_BATCH_CREATE_FAILED";

//
export const VALIDATION_LOADING_REQUESTED = "VALIDATION_LOADING_REQUESTED";
export const VALIDATION_LOADING_SUCCEEDED = "VALIDATION_LOADING_SUCCEEDED";
export const VALIDATION_LOADING_FAILED = "VALIDATION_LOADING_FAILED";

//
export const DEPARTMENT_ADDING_REQUESTED = "DEPARTMENT_ADDING_REQUESTED";
export const DEPARTMENT_ADDING_SUCCEEDED = "DEPARTMENT_ADDING_SUCCEEDED";
export const DEPARTMENT_ADDING_FAILED = "DEPARTMENT_ADDING_FAILED";
