import { AUTH_ERROR, LOGIN_FAIL, LOGIN_REQUESTED, LOGIN_SUCCESS, LOGOUT, USER_LOADED } from "../action_types";

const initialState = {
  token: localStorage.getItem("token"), //jwt is gonna get stored in local
  role: localStorage.getItem("role"),
  isAuthenticated: null, // used for showing stuff that only logged in can see
  loading: true, // before showing anything, make sure that loading is done
  user: null, // stores user-stuff
  company: null,
  department: null,
};

export const authReducer = function (state = initialState, givenAction) {
  const { type, payload } = givenAction;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        role: payload.data.role,
        company: payload.data.company,
        department: payload.data.department,
      };

    case LOGIN_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.data.token);
      return {
        ...state,
        token: payload.data.token,
        isAuthenticated: true,
        loading: false,
        role: payload.data.role,
        company: payload.data.company,
        department: payload.data.department,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        role: null,
        user: null,
        department: null,
        company: null,
      };
    default:
      return state;
  }
};
