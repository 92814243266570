import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import { app } from "../../../../App";
import setAuthToken from "../../../utils/set_auth_token";
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOGOUT,
  ORDERS_RESET,
  USER_LOADED,
} from "../action_types";
import { setAlertAction } from "../alerts/alert_actions";
import { loadCompaniesAction } from "../companies/company_actions";
import { loadDocumentsAction } from "../documents/documents_actions";
import { loadOrdersAction } from "../orders/order_actions";

// Authentificate-action
export const loadUserAction = () => async (dispatch) => {
  let token = localStorage.getItem("token")

  if (token) {
    setAuthToken(token);
  }
  try {
    const res = await app.functions("europe-west3").httpsCallable("app-api")({ function_name: "auth-login" });
    dispatch(_loadInitialData(res.data));

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login-action
export const loginAction = (email, password) => async (dispatch) => {

  try {
    dispatch({ type: LOGIN_REQUESTED });
    await firebase.auth().signInWithEmailAndPassword(email, password);
    const res = await app.functions("europe-west3").httpsCallable("app-api")({ function_name: "auth-login" });
    dispatch(_loadInitialData(res.data));

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    // dispatch(loadUserAction());
  } catch (err) {
    console.log(err);
    if (err?.code != null) {
      switch (err.code) {
        case "auth/user-not-found":
        case "auth/wrong-password":
          dispatch(setAlertAction("Fehler bei der Anmeldung. Falsche Anmeldedaten.", "danger"));
          break;
        case "auth/too-many-requests":
          dispatch(setAlertAction("Zu viele Anmeldeversuche. Versuchen Sie es bitte gleich erneut.", "danger"));
          break;
        default:
          dispatch(setAlertAction("Fehler bei der Anmeldung.", "danger"));
          break;
      }
    } else {
      dispatch(setAlertAction(err?.response?.data?.message, "danger"));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout / clear
export const logoutAction = () => (dispatch) => {
  firebase.auth().signOut();
  dispatch({
    type: ORDERS_RESET,
  });
  dispatch({
    type: LOGOUT,
  });
};

const _loadInitialData = (resBody) => (dispatch) => {
  const { role, company, department } = resBody.data;
  if (role !== "admin") {
    if (company?.data?.sql_access) {
      dispatch(loadOrdersAction(0, company.id, company.data.sql_access));
    }

    dispatch(loadDocumentsAction(company.id, department));
  } else {
    dispatch(loadCompaniesAction());
  }
};
