import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAlertAction } from "../../../redux/actions_reducers/alerts/alert_actions";
import { createCompanyAction, loadCompaniesAction } from "../../../redux/actions_reducers/companies/company_actions";
import { Loader } from "../../loader";

function CreateCompany({ createCompanyAction, setAlertAction }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // Handling the state that contains form-data
  const [formData, setFormData] = useState({
    company_name: "",
    company_sql_id: "",
  }); // make data available to complete component
  const { company_name, company_sql_id } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  // Handling form-submit
  const onSubmit = async (e) => {
    e.preventDefault();
    if (company_name === "" || company_name == null) {
      setAlertAction("Bitte geben sie den Namen des Kunden ein", "danger", 2000);
    } else {
      setLoading(true);

      let _sql_access = true;
      if (company_sql_id == null || company_sql_id === "") {
        _sql_access = false;
      }

      //   const body = { id: _company_id, name: company_name, sql_access: _sql_access };
      //   const config = { ContentType: "application/json" };
      //   await axios.post(basePath + "/api/company/new", body, config).then(
      const res = await createCompanyAction(company_sql_id, company_name, _sql_access);
      if (res.status === "okay") {
        history.push("/admin/companies/edit", res.data);
      }

      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Fragment>
        <h1 className="large">KUNDEN ANLEGEN</h1>
        {Loader()}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <section className="container">
          <h1 className="large">KUNDEN ANLEGEN</h1>
          <p>Bitte geben Sie den Firmennamen und die Mandanten-ID (Identifier) des Kunden ein.</p>
          <form className="form my-2" onSubmit={onSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Firmenname"
                name="company_name"
                value={company_name}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Mandanten-ID des Kunden"
                name="company_sql_id"
                value={company_sql_id}
                onChange={(e) => onChange(e)}
              />
            </div>
            <input type="submit" className="dark-grey button-dark right50" value="Kunden anlegen" />
          </form>
        </section>
      </Fragment>
    );
  }
}

CreateCompany.propTypes = {
  loading: PropTypes.bool,
  companies: PropTypes.object,
  companiesResult: PropTypes.string,
};

const extractPropsFromStore = (state) => ({
  loading: state.companiesReducer.loading,
  companies: state.companiesReducer.companies,
  companiesResult: state.companiesReducer.companiesResult,
});

// null means that no properties are extracted from the redux-store
export default connect(extractPropsFromStore, { createCompanyAction, loadCompaniesAction, setAlertAction })(
  CreateCompany
);
