import PropTypes from "prop-types"; // typing for props
import React, { Fragment } from "react";
import { connect } from "react-redux";

function Alert(props) {
  if (props.alerts !== null && props.alerts.length > 0) {
    return props.alerts.map((alert) => {
      return (
        <Fragment>
          <br key={alert.actionId + "a"} />
          <div key={alert.actionId} className={`alert alert-${alert.alertType}`}>
            {alert.msg}
          </div>
        </Fragment>
      );
    });
  } else {
    return <div></div>;
  }
}

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};
const extractPropsFromStore = (state) => ({
  alerts: state.alertReducer,
});

// Docs: "Connects a React component to a Redux store"
// By exporting the connection you don't need to export the function itself anymore
export default connect(extractPropsFromStore)(Alert);
