import Select, { components } from "react-select";

export const CustomMultiSelect = ({ classNameForDiv, options, placeholder, onChange } = {}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "8px",
      fontSize: "1.1rem",
      border: "1px solid grey",
      borderRadius: 0,
    }),
  };

  const CaretDownIcon = () => {
    return <i className="fas fa-caret-down" />;
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className={classNameForDiv}>
      <Select
        isClearable
        styles={customStyles}
        isMulti={true}
        components={DropdownIndicator}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
      />
    </div>
  );
};
//
//
