import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setAlertAction } from "../../../redux/actions_reducers/alerts/alert_actions";
import { deleteCompanyAction } from "../../../redux/actions_reducers/companies/company_actions";
import { parseCompaniesToSelect } from "../../../utils/parse_companies_to_select";
import { CustomSelect } from "../../global/select";
import { Loader } from "../../loader";

// const testCompanies = [{ value: 122221, label: "zmdesign", color: "#00B8D9", isFixed: true }];

function Companies(props) {
  const { loading, companies, companiesResult, deleteCompanyAction, setAlertAction } = props;

  const [selectedCompany, setselectedCompany] = useState(null);

  //   const onChange = (e) => setselectedCompany({ ...selectedCompany, [e.target.name]: e.target.value });
  const onChange = (option) => setselectedCompany(option?.value);

  const onDelete = () => {
    if (selectedCompany == null) {
      setAlertAction("Es wurde kein Kunde ausgewählt", "danger", 1500);
    } else {
      setselectedCompany(null);
      deleteCompanyAction(selectedCompany?.id);
    }
  };

  const history = useHistory();
  const onEditClicked = () => {
    if (selectedCompany == null) {
      setAlertAction("Es wurde kein Kunde ausgewählt", "danger", 1500);
    } else {
      history.push({ pathname: "/admin/companies/edit", state: selectedCompany });
    }
  };

  if (loading) {
    return (
      <Fragment>
        <h1 className="large">KUNDENÜBERSICHT</h1>
        {Loader()}
      </Fragment>
    );
  } else if (!loading && companiesResult === "success") {
    return (
      <Fragment>
        <h1 className="large">KUNDENÜBERSICHT</h1>
        <div className="my-2">
          <Link to="/admin/companies/new" className="dark-grey button-dark link-as-button text-center">
            Kunden hinzufügen
          </Link>
          {CustomSelect({
            classNameForDiv: "pt-1 pb",
            options: parseCompaniesToSelect(companies),
            placeholder: "Kunden auswählen",
            onChange: onChange,
          })}
          <div className="flex">
            <button className="mr button-dark dark-grey" onClick={onEditClicked}>
              Bearbeiten
            </button>
            <button className="ml button-dark dark-grey" onClick={onDelete}>
              Löschen
            </button>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <div></div>;
  }
}

Companies.propTypes = {};

const extractPropsFromStore = (state) => ({
  loading: state.companiesReducer.loading,
  companies: state.companiesReducer.companies,
  companiesResult: state.companiesReducer.companiesResult,
});

export default connect(extractPropsFromStore, { deleteCompanyAction, setAlertAction })(Companies);
