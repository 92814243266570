import { DOCUMENTS_LOADING_REQUESTED, DOCUMENTS_LOADING_SUCCEEDED, DOCUMENTS_LOADING_FAILED } from "../action_types";
import firebase from "firebase/app";
import "firebase/storage";
import { formatToDate } from "../../../utils/format_date";

// Authentificate-action
export const loadDocumentsAction = (company_id, department_id) => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENTS_LOADING_REQUESTED,
    });

    let files;
    if (department_id === "" || department_id == null || department_id === undefined) {
      files = await _getAll(company_id);
    } else {
      files = await _getForDepartment(company_id, department_id);
    }

    let documents = [];
    for (let i = 0; i < files.length; i++) {
      const url = await files[i].getDownloadURL();
      const name = files[i].name;
      const path = files[i].location.path_;
      const metaData = await files[i].getMetadata();
      const date = formatToDate(metaData.timeCreated);
      const size = _formatBytes(metaData.size);
      documents.push({ name, url, date, size, path });
    }

    dispatch({
      type: DOCUMENTS_LOADING_SUCCEEDED,
      payload: { data: documents },
    });
  } catch (err) {
    dispatch({
      type: DOCUMENTS_LOADING_FAILED,
    });
  }
};

function _formatBytes(a, b = 2) {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
}

async function _getForDepartment(company_id, department_id) {
  const folderFiles = await firebase
    .storage()
    .ref()
    .child(company_id + "/" + department_id + "/")
    .listAll()
    .then((res) => res.items);

  const baseFiles = await firebase
    .storage()
    .ref()
    .child(company_id + "/")
    .listAll()
    .then((res) => res.items);

  return [...folderFiles, ...baseFiles];
}

async function _getAll(company_id) {
  const storageRef = firebase
    .storage()
    .ref()
    .child(company_id + "/");

  let _files = await storageRef.listAll().then((res) => res.items);
  const folders = await storageRef.listAll().then((res) => res.prefixes);

  for (let i = 0; i < folders.length; i++) {
    const folder = folders[i];
    const _foldersFiles = await firebase
      .storage()
      .ref()
      .child(company_id + "/" + folder.name + "/")
      .listAll()
      .then((res) => res.items);

    _files = [..._files, ..._foldersFiles];
  }

  return _files;
}
