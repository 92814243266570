import "font-awesome/css/font-awesome.min.css";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import img from "../../../../assets/logo.png";
import { setAlertAction } from "../../../redux/actions_reducers/alerts/alert_actions";
import { sendResetEmailAction } from "../../../redux/actions_reducers/contacts/contacts_actions";

function ForgotPasswordPage(props) {
  const { setAlertAction, sendResetEmailAction } = props;
  const queryString = props.location.search.split("?")[1];
  const parameterPairs = queryString.split("&");
  let params = {};
  parameterPairs.forEach((parameterPair) => {
    const keyValue = parameterPair.split("=");
    params[keyValue[0]] = keyValue[1];
  });

  const [resetEmailAddress, setResetEmailAddress] = useState(params["email"].toString().trim() ?? "");

  const onSubmit = async (e) => {
    e.preventDefault();
    if (resetEmailAddress == null || resetEmailAddress.length === 0) {
      setAlertAction("Es ist keine Email angegeben", "danger");
    } else {
      sendResetEmailAction(resetEmailAddress);
    }
  };

  return (
    <Fragment>
      <section className="container">
        <div>
          <img className="img-center" src={img} alt="test"></img>
        </div>
        <h1 className="large text-primary text-center">Passwort zurücksetzen</h1>
        <form className="form m-2" onSubmit={onSubmit}>
          <div className="">IHRE EMAIL</div>
          <input
            className="icon-input-field mb-1"
            type="text"
            value={resetEmailAddress}
            onChange={(e) => setResetEmailAddress(e.target.value.toString().trim())}
          ></input>
          <input type="submit" className="light-grey button-light" value="LINK ANFORDERN" />
        </form>
      </section>
    </Fragment>
  );
}

ForgotPasswordPage.propTypes = {
  setAlertAction: PropTypes.func.isRequired,
};

const extractPropsFromStore = () => ({});

export default connect(extractPropsFromStore, { setAlertAction, sendResetEmailAction })(ForgotPasswordPage);

// import "font-awesome/css/font-awesome.min.css";
// import { PropTypes } from "prop-types";
// import { Fragment, useState } from "react";
// import { connect } from "react-redux";
// import img from "../../../../assets/logo.png";
// import { setAlertAction } from "../../../redux/actions_reducers/alerts/alert_actions";
// import { sendResetEmailAction } from "../../../redux/actions_reducers/contacts/contacts_actions";

// function ForgotPasswordPage(props) {
//   let params = {};
//   try {
//     const { setAlertAction, sendResetEmailAction } = props;
//     const queryString = props.location.search.split("?")[1];
//     const parameterPairs = queryString.split("&");

//     parameterPairs.forEach((parameterPair) => {
//       const keyValue = parameterPair.split("=");
//       params[keyValue[0]] = keyValue[1];
//     });
//   } catch (_) {}

//   const [resetEmailAddress, setResetEmailAddress] = useState(params["email"]?.toString()?.trim() ?? "");

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     if (resetEmailAddress == null || resetEmailAddress.length === 0) {
//       setAlertAction("Es ist keine Email angegeben", "danger");
//     } else {
//       sendResetEmailAction(resetEmailAddress);
//     }
//   };

//   return (
//     <Fragment>
//       <section className="container">
//         <div>
//           <img className="img-center" src={img} alt="test"></img>
//         </div>
//         <h1 className="large text-primary text-center">Passwort vergessen</h1>
//         <form className="form m-2" onSubmit={onSubmit}>
//           <div className="">IHRE EMAIL</div>
//           <input
//             className="icon-input-field mb-1"
//             type="text"
//             value={resetEmailAddress}
//             onChange={(e) => setResetEmailAddress(e.target.value.toString().trim())}
//           ></input>
//           <input type="submit" className="light-grey button-light" value="LINK ANFORDERN" />
//         </form>
//       </section>
//     </Fragment>
//   );
// }

// ForgotPasswordPage.propTypes = {
//   setAlertAction: PropTypes.func.isRequired,
// };

// const extractPropsFromStore = () => ({});

// export default connect(extractPropsFromStore, { setAlertAction, sendResetEmailAction })(ForgotPasswordPage);
