import firebase from "firebase/app";
import "firebase/functions";
import "firebase/storage";
import { app } from "../../../../App";
import {
  COMPANIES_LOADING_FAILED,
  COMPANIES_LOADING_REQUESTED,
  COMPANIES_LOADING_SUCCEEDED,
  COMPANY_CREATE_FAILED,
  COMPANY_CREATE_REQUESTED,
  COMPANY_CREATE_SUCCEEDED,
  COMPANY_DELETE_REQUESTED,
  COMPANY_DELETE_SUCCEEDED,
  DEPARTMENT_CREATE_REQUESTED,
  DEPARTMENT_CREATE_SUCCEEDED,
  DEPARTMENT_CREATE_FAILED,
  DEPARTMENT_DELETE_REQUESTED,
  DEPARTMENT_DELETE_SUCCEEDED,
  DEPARTMENT_DELETE_FAILED,
  COMPANY_DELETE_FAILED,
  CHANGE_SQL_ID_REQUESTED,
  CHANGE_SQL_ID_FAILED,
  CHANGE_SQL_ID_SUCCEEDED,
} from "../action_types";
import { setAlertAction } from "../alerts/alert_actions";

export const loadCompaniesAction = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPANIES_LOADING_REQUESTED,
    });
    const res = await app.functions("europe-west3").httpsCallable("app-api")({
      function_name: "companies-get_all_companies",
    });

    dispatch({
      type: COMPANIES_LOADING_SUCCEEDED,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: COMPANIES_LOADING_FAILED,
    });
  }
};

export const createCompanyAction = (id, name, sql_access) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_CREATE_REQUESTED,
    });

    const res = await app.functions("europe-west3").httpsCallable("app-api")({
      function_name: "companies-create_company",
      sql_id: id,
      name,
      sql_access,
    });

    if (res.data.status === "okay") {
      dispatch({
        type: COMPANY_CREATE_SUCCEEDED,
        payload: res.data.data,
      });
      return res.data;
    } else {
      dispatch(setAlertAction(res.data.message, "danger"));
      dispatch({
        type: COMPANY_CREATE_FAILED,
      });
      return { status: "error" };
    }
  } catch (error) {
    dispatch(setAlertAction("Fehler beim erstellen des Kunden", "danger"));
    console.log(error);

    dispatch({
      type: COMPANY_CREATE_FAILED,
    });
    return { status: "error" };
  }
};

export const deleteCompanyAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_DELETE_REQUESTED,
    });
    // const res = await axios.post(basePath + "/api/company/delete", { id }, config);
    // const res = await app.functions("europe-west3").httpsCallable("companies-delete_company")({ id });
    const res = await app.functions("europe-west3").httpsCallable("app-api")({
      function_name: "companies-delete_company",
      id,
    });

    // Delete all files and folders for that company
    try {
      const storageRef = firebase
        .storage()
        .ref()
        .child(id + "/");

      let _files = await storageRef.listAll().then((res) => res.items);
      const folders = await storageRef.listAll().then((res) => res.prefixes);

      for (let i = 0; i < folders.length; i++) {
        const folder = folders[i];
        const _foldersFiles = await firebase
          .storage()
          .ref()
          .child(id + "/" + folder.name + "/")
          .listAll()
          .then((res) => res.items);

        _files = [..._files, ..._foldersFiles];
      }
      for (let i = 0; i < _files.length; i++) {
        const file = _files[i];
        file.delete();
      }
    } catch (err) {
      console.log(err);
    }

    dispatch({
      type: COMPANY_DELETE_SUCCEEDED,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: COMPANY_DELETE_FAILED,
    });
  }
};

export const deleteDepartmentAction = (company_id, department_name) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_DELETE_REQUESTED,
    });
    // const res = await axios.post(basePath + "/api/company/delete", { id }, config);
    const res = await app.functions("europe-west3").httpsCallable("app-api")({
      function_name: "companies-delete_department_from_company",
      company_id,
      department_name,
    });

    // Delete the files for that department from storage
    const files = await firebase
      .storage()
      .ref()
      .child(company_id + "/" + department_name + "/")
      .listAll()
      .then((res) => res.items);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      file.delete();
    }

    dispatch({
      type: DEPARTMENT_DELETE_SUCCEEDED,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (error) {
    console.log(error);
    dispatch({
      type: DEPARTMENT_DELETE_FAILED,
    });
  }
  //   return await app.functions("europe-west3").httpsCallable("companies-delete_department_from_company")({
  //     company_id,
  //     department_name,
  //   });
};
export const addDepartmentAction = (company_id, department_name) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_CREATE_REQUESTED,
    });
    // const res = await axios.post(basePath + "/api/company/delete", { id }, config);
    const res = await app.functions("europe-west3").httpsCallable("app-api")({
      function_name: "companies-add_department_for_company",
      company_id,
      department_name,
    });

    dispatch({
      type: DEPARTMENT_CREATE_SUCCEEDED,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (error) {
    console.log(error, error.stack);
    dispatch({
      type: DEPARTMENT_CREATE_FAILED,
    });
  }
  //   return await app.functions("europe-west3").httpsCallable("companies-add_department_for_company")({
  //     company_id,
  //     department_name,
  //   });
};

export const changeSqlIdAction = (company_id, new_sql_id) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_SQL_ID_REQUESTED,
    });
    const res = await app.functions("europe-west3").httpsCallable("app-api")({
      function_name: "companies-change_sql_identifier",
      company_id,
      new_sql_id,
    });
    console.log(res.data);

    if (res.data.status === "error") {
      dispatch({
        type: CHANGE_SQL_ID_FAILED,
      });
      dispatch(setAlertAction(res.data.message, "danger"));
    }
    dispatch({
      type: CHANGE_SQL_ID_SUCCEEDED,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: CHANGE_SQL_ID_FAILED,
    });
    console.log(err);
    dispatch(setAlertAction(err.message.toString(), "danger"));
  }
};
