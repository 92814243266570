import { DOCUMENTS_LOADING_REQUESTED, DOCUMENTS_LOADING_SUCCEEDED, DOCUMENTS_LOADING_FAILED } from "../action_types";

const initialState = {
  documentsLoading: false,
  documents: [],
  documentsResult: null,
};

export const documentsReducer = function (state = initialState, givenAction) {
  const { type, payload } = givenAction;

  switch (type) {
    case DOCUMENTS_LOADING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case DOCUMENTS_LOADING_SUCCEEDED:
      return {
        loading: false,
        documents: payload.data,
        documentsResult: "success",
      };
    case DOCUMENTS_LOADING_FAILED:
      return {
        loading: false,
        documents: [],
        documentsResult: "failure",
      };
    default:
      return state;
  }
};
