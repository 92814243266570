import {
  ORDERS_LOADING_REQUESTED,
  ORDERS_LOADING_SUCCEEDED,
  ORDERS_LOADING_FAILED,
  ORDERS_RESET,
} from "../action_types";

const initialState = {
  ordersLoading: false,
  orders: [],
  ordersResult: null,
};

export const ordersReducer = function (state = initialState, givenAction) {
  const { type, payload } = givenAction;

  switch (type) {
    case ORDERS_LOADING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case ORDERS_LOADING_SUCCEEDED:
      return {
        loading: false,
        orders: [...state.orders, ...payload],
        ordersResult: "success",
      };
    case ORDERS_LOADING_FAILED:
      return {
        loading: false,
        orders: [],
        ordersResult: "failure",
      };
    case ORDERS_RESET:
      return {
        loading: false,
        orders: [],
        ordersResult: null,
      };
    default:
      return state;
  }
};
