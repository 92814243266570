import { Fragment } from "react";

export const csvWidget = (history, company) => (
  <Fragment>
    <h3>Kontakte hinzufügen</h3>
    <button className="dark-grey button-dark" onClick={() => history.push("/admin/companies/edit/batch", company)}>
      CSV UPLOAD
    </button>
  </Fragment>
);
