import firebase from "firebase/app";
import "firebase/storage";
import { PropTypes } from "prop-types";
import { createRef, Fragment, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAlertAction } from "../../../redux/actions_reducers/alerts/alert_actions";
import { parseCompaniesToSelect } from "../../../utils/parse_companies_to_select";
import FileUploader from "../../global/file_upload";
import { CustomSelect } from "../../global/select";
import { Loader } from "../../loader";

function Documents(props) {
  const { loading, companies, companiesResult, setAlertAction } = props;
  const history = useHistory();

  //
  const [fileName, setFileName] = useState("");
  const [departmentName, setDepartmentName] = useState(null);

  //
  const [selectedCompany, setselectedCompany] = useState(null);
  const onChange = (e) => setselectedCompany(e?.value);

  //   const parseCompaniesToSelect = (_companies) => {
  //     const _comps = [];
  //     for (const key in _companies) {
  //       if (_companies.hasOwnProperty(key)) {
  //         const company = _companies[key];
  //         _comps.push({ label: company.name, value: { id: key, data: company } });
  //       }
  //     }
  //     return _comps;
  //   };

  const parseDepartmentsToSelect = () => {
    if (selectedCompany != null && !selectedCompany?.data?.departments.size > 0) {
      const _departments = selectedCompany?.data?.departments.map((department) => ({
        value: department,
        label: department,
      }));

      //   const _departments = {};
      const appendix = { label: "KEINE ABTEILUNG", value: "" };
      _departments.push(appendix);
      return _departments;
    }
  };

  //
  const [selectedFile, setSelectedFile] = useState(null);
  const onFileSelected = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  //tmp
  const hiddenFileInput = createRef();

  const onSubmit = async (e) => {
    if (selectedCompany == null) {
      setAlertAction("Keinen Kunden ausgewählt", "danger");
    } else if (departmentName == null) {
      setAlertAction("Nicht angegeben, ob für eine oder für alle Abteilungen", "danger");
    } else if (selectedFile == null) {
      setAlertAction("Keine Datei ausgewählt", "danger");
    } else {
      if (selectedFile.name.indexOf("/") > -1 || departmentName.indexOf("/") > -1) {
        return setAlertAction("Dateiname und Abteilung dürfen keinen '/' enthalten", "danger");
      } else if (departmentName.indexOf(".") > -1) {
        return setAlertAction("Abteilung darf keinen '.' enthalten", "danger");
      }
      // Rename the document
      let newName = selectedFile.name;
      if (fileName !== "" && fileName != null) {
        newName = fileName;
        if (newName.indexOf(".") > -1) {
          return setAlertAction("Dateiname darf keinen '.' enthalten", "danger");
        }
        newName = fileName + "." + selectedFile.type.split("/")[1];
      }

      const departmentPath = departmentName === "" ? "" : departmentName + "/";
      const storageRef = firebase.storage().ref(`${selectedCompany.id.toString()}/${departmentPath}${newName}`);
      const uploadTask = storageRef.put(selectedFile);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, null, null, async () => {
        // await uploadTask.snapshot.ref.getDownloadURL();
        setAlertAction("Upload erfolgreich", "success", 3500);
      });
    }
  };

  if (loading) {
    return (
      <Fragment>
        <h1 className="large">DOKUMENTE HOCHLADEN</h1>
        {Loader()}
      </Fragment>
    );
  } else if (!loading && companiesResult === "success") {
    return (
      <Fragment>
        <div>
          <h1 className="large">DOKUMENTE HOCHLADEN</h1>
          <p className="text-bold">Wählen Sie die Firma aus und laden Sie das entsprechende Dokument hoch</p>
        </div>

        <div className="my">
          <div className="pt-1 pb flex">
            {CustomSelect({
              classNameForDiv: "auto-flex mr",
              options: parseCompaniesToSelect(companies),
              placeholder: "Kunden auswählen",
              onChange: onChange,
            })}
            {CustomSelect({
              classNameForDiv: "auto-flex ml",
              options: parseDepartmentsToSelect(),
              placeholder: "Abteilung auswählen",
              onChange: (option) => setDepartmentName(option?.value),
            })}
          </div>
          <div className="mb-4">
            <form className="form">
              <input type="text" placeholder="Dokument benennen" onChange={(e) => setFileName(e.target.value)}></input>
              {FileUploader({
                classNameForButton: "dark-grey button-dark default-text-style",
                text: "Datei auswählen",
                hiddenFileInput: hiddenFileInput,
                onFileSelected: onFileSelected,
              })}
            </form>
            <button className="button-danger right50" value="Upload" onClick={(e) => onSubmit(e)}>
              Upload
            </button>
          </div>
        </div>
        <div className="mt-4 hack">
          <p className="text-bold mt-4">Sie möchten die bereits freigegebenen Dokumente für einen Kunden bearbeiten?</p>
          <button className="dark-grey button-dark my" onClick={() => history.push("/admin/documents/delete")}>
            Bestehende Dokumente verwalten
          </button>
        </div>
      </Fragment>
    );
  } else {
    return <div></div>;
  }
}

Documents.propTypes = {
  setAlertAction: PropTypes.func.isRequired,
};

const extractPropsFromStore = (state) => ({
  loading: state.companiesReducer.loading,
  companies: state.companiesReducer.companies,
  companiesResult: state.companiesReducer.companiesResult,
});

export default connect(extractPropsFromStore, { setAlertAction })(Documents);
