import { saveAs } from "file-saver";
import { parse } from "papaparse";
import PropTypes from "prop-types";
import React, { createRef, Fragment, useState } from "react";
import { connect } from "react-redux";
import { setAlertAction } from "../../../redux/actions_reducers/alerts/alert_actions";
import { addContactBatchAction } from "../../../redux/actions_reducers/contacts/contacts_actions";
import FileUploader from "../../global/file_upload";

function UploadCSV(props) {
  const { addContactBatchAction, setAlertAction } = props;
  //   const history = useHistory();

  const [selectedFile, setSelectedFile] = useState(null);
  const company = props.location.state;

  const onFileSelected = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const _convertDepartmentString = (departmentString) => {
    return departmentString.trim() === "" ? null : departmentString;
  };

  // Handling form-submit
  const onUpload = async (e) => {
    e.preventDefault();
    if (selectedFile == null) {
      setAlertAction("Keine CSV-Datei ausgewählt", "danger", 2500);
    } else {
      const text = await selectedFile.text();
      const json = parse(text);

      const uploadableJson = json.data
        .slice(1)
        .filter((e) => e.length >= 3)
        .map((res) =>
          Object({ first_name: res[0], last_name: res[1], email: res[2], department: _convertDepartmentString(res[3]) })
        );
      addContactBatchAction(uploadableJson, company);
    }
  };

  //tmp
  const hiddenFileInput = createRef();

  const _downloadCSVtemplate = () => {
    const blob = new Blob(["Vorname,Nachname,Email,Abteilung"], { type: "text/csv" });
    saveAs(blob, "csv_vorlage.csv");
  };

  //   if (loading) {
  //     return <div>Loading</div>;
  //   } else if (!loading && validationResult == null) {
  return (
    <Fragment>
      <div className="container">
        <h1 className="large text-primary">.CSV HOCHLADEN: {company.data.name}</h1>
        Die .csv Vorlage{" "}
        <p className="link link-black inline" onClick={_downloadCSVtemplate}>
          herunterladen
        </p>
        . Bitte achten Sie auf das richtige Format.
        {FileUploader({
          classNameForButton: "dark-grey button-dark default-text-style",
          text: "Datei auswählen",
          hiddenFileInput: hiddenFileInput,
          onFileSelected: onFileSelected,
          accept: ".csv",
        })}
        <button className=" button-danger right50 mb-4" onClick={(e) => onUpload(e)}>
          Upload
        </button>
      </div>
    </Fragment>
  );
  //   } else if (!loading && validationResult === "success") {
  //     return (
  //       <div className="container">
  //         <div className="container inline">{_successesToHTML(validation.successes, onUpload)}</div>
  //         <div className="container inline">{_failuresToHTML(validation.failures)}</div>
  //       </div>
  //     );
  //   } else {
  //     return <div></div>;
  //   }
}

UploadCSV.propTypes = {
  isAuthenticated: PropTypes.bool,
  uid: PropTypes.string,
};

const extractPropsFromStore = (state) => ({});

// null means that no properties are extracted from the redux-store
export default connect(extractPropsFromStore, { addContactBatchAction, setAlertAction })(UploadCSV);
