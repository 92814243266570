export const parseCompaniesToSelect = (_companies) => {
  const _comps = [];
  for (const key in _companies) {
    if (_companies.hasOwnProperty(key)) {
      const company = _companies[key];
      _comps.push({ label: company.name, value: { id: key, data: company } });
    }
  }
  return _comps;
};
