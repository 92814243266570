import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import img from "../../../../assets/logo.png";
import { setAlertAction } from "../../../redux/actions_reducers/alerts/alert_actions";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";

function PasswordReset(props) {
  const { setAlertAction } = props;
  const queryString = props.location.search.split("?")[1];
  const parameterPairs = queryString.split("&");
  let params = {};
  parameterPairs.forEach((parameterPair) => {
    const keyValue = parameterPair.split("=");
    params[keyValue[0]] = keyValue[1];
  });

  const history = useHistory();

  const [newPassword, setNewPassword] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (newPassword == null || newPassword.length < 6) {
      setAlertAction("Ihr Passwort muss mindestens 6 Zeichen lang sein", "danger");
    } else {
      await firebase
        .auth()
        .confirmPasswordReset(params.oobCode, newPassword)
        .then(() => {
          // Success
          setAlertAction("Passwort erfolgreich festgelegt.", "success");
          history.push("/");
        })
        .catch((err) => {
          switch (err.code) {
            case "auth/expired-action-code":
              setAlertAction(
                "Ihr Code zum Password-Reset ist abgelaufen. Bitte kontaktieren Sie den Support.",
                "danger"
              );
              break;
            case "auth/invalid-action-code":
              setAlertAction(
                "Der Code zum Password-Reset ist nicht (mehr) gültig. Bitte kontaktieren Sie den Support.",
                "danger"
              );
              break;
            case "auth/user-not-found":
              setAlertAction(
                "Ihr Nutzerkonto konnte nicht gefunden werden. Bitte kontaktieren Sie den Support",
                "danger"
              );
              break;
            default:
              console.log(err);
              setAlertAction("Fehler beim festlegen des Passworts. ", "danger");
              break;
          }
        });
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <Fragment>
      <section className="container">
        <div>
          <img className="img-center" src={img} alt="test"></img>
        </div>
        <h1 className="large text-primary text-center">Passwort einrichten</h1>
        <form className="form m-2" onSubmit={onSubmit}>
          <div className="">NEUES PASSWORT</div>
          <div className="icon-input-container">
            <input
              className="icon-input-field"
              type={passwordShown ? "text" : "password"}
              onChange={(e) => setNewPassword(e.target.value)}
            ></input>
            <i
              className={passwordShown ? "fa fa-eye-slash input-icon" : "fa fa-eye input-icon"}
              onClick={togglePasswordVisiblity}
            ></i>
          </div>
          <input type="submit" className="light-grey button-light" value="BESTÄTIGEN" />
        </form>
      </section>
    </Fragment>
  );
}

PasswordReset.propTypes = {
  setAlertAction: PropTypes.func.isRequired,
};

const extractPropsFromStore = () => ({});

export default connect(extractPropsFromStore, { setAlertAction })(PasswordReset);
