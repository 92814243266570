import {
  COMPANIES_LOADING_REQUESTED,
  COMPANIES_LOADING_SUCCEEDED,
  COMPANIES_LOADING_FAILED,
  COMPANY_CREATE_REQUESTED,
  COMPANY_CREATE_SUCCEEDED,
  COMPANY_CREATE_FAILED,
  COMPANY_DELETE_REQUESTED,
  COMPANY_DELETE_SUCCEEDED,
  DEPARTMENT_DELETE_REQUESTED,
  DEPARTMENT_DELETE_SUCCEEDED,
  DEPARTMENT_DELETE_FAILED,
  DEPARTMENT_CREATE_REQUESTED,
  DEPARTMENT_CREATE_FAILED,
  DEPARTMENT_CREATE_SUCCEEDED,
  CHANGE_SQL_ID_FAILED,
  CHANGE_SQL_ID_REQUESTED,
  CHANGE_SQL_ID_SUCCEEDED,
} from "../action_types";

const initialState = {
  companiesLoading: false,
  companies: null,
  companiesResult: null,
};

export const companiesReducer = function (state = initialState, givenAction) {
  const { type, payload } = givenAction;

  switch (type) {
    //! READ
    case COMPANY_CREATE_REQUESTED:
    case CHANGE_SQL_ID_REQUESTED:
    case DEPARTMENT_DELETE_REQUESTED:
    case DEPARTMENT_CREATE_REQUESTED:
    case COMPANY_DELETE_REQUESTED:
    case COMPANIES_LOADING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case COMPANY_DELETE_SUCCEEDED:
    case COMPANIES_LOADING_SUCCEEDED:
      return {
        ...state,
        loading: false,
        companies: payload.data,
        companiesResult: "success",
      };
    case COMPANY_CREATE_FAILED:
    case CHANGE_SQL_ID_FAILED:
    case DEPARTMENT_DELETE_FAILED:
    case DEPARTMENT_CREATE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case COMPANIES_LOADING_FAILED:
      return {
        ...state,
        loading: false,
        companies: {},
        companiesResult: "failure",
      };

    case COMPANY_CREATE_SUCCEEDED:
    case DEPARTMENT_DELETE_SUCCEEDED:
    case DEPARTMENT_CREATE_SUCCEEDED:
    case CHANGE_SQL_ID_SUCCEEDED:
      const companies = state.companies;
      companies[payload.id] = payload.data;
      return {
        ...state,
        companies,
        loading: false,
      };
    default:
      return state;
  }
};
