import { app } from "../../../../App";
import {
  CONTACTS_LOADING_FAILED,
  CONTACTS_LOADING_REQUESTED,
  CONTACTS_LOADING_SUCCEEDED,
  CONTACT_CREATE_FAILED,
  CONTACT_CREATE_REQUESTED,
  CONTACT_CREATE_SUCCEEDED,
  CONTACT_DELETE_FAILED,
  CONTACT_DELETE_REQUESTED,
  CONTACT_DELETE_SUCCEEDED,
} from "../action_types";
import { setAlertAction } from "../alerts/alert_actions";

// Authentificate-action
export const loadContactsAction = (company_id) => async (dispatch) => {
  //   const config = { headers: { "Content-Type": "application/json" } };
  //   const body = JSON.stringify({ company_id: company_id });

  try {
    dispatch({
      type: CONTACTS_LOADING_REQUESTED,
    });
    // const res = await axios.post(basePath + "/api/people/company", body, config);
    const callable = app.functions("europe-west3").httpsCallable("app-api");
    const res = await callable({ function_name: "contacts-get_contacts_of_company", company_id });

    dispatch({
      type: CONTACTS_LOADING_SUCCEEDED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CONTACTS_LOADING_FAILED,
    });
  }
};

export const deleteContactsAction = (contact_ids, company_id) => async (dispatch) => {
  //   const config = { headers: { "Content-Type": "application/json" } };
  //   const body = JSON.stringify({ ids: contact_ids, company_id });

  try {
    dispatch({
      type: CONTACT_DELETE_REQUESTED,
    });
    // const res = await axios.post(basePath + "/api/people/deletecomp", body, config);
    const callable = app.functions("europe-west3").httpsCallable("app-api");
    const res = await callable({
      function_name: "companies-delete_contact_from_company",
      ids: contact_ids,
      company_id,
    });

    dispatch({
      type: CONTACT_DELETE_SUCCEEDED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CONTACT_DELETE_FAILED,
    });
  }
};
export const addContactAction = (first_name, last_name, email, company_id, department) => async (dispatch) => {
  //   const config = { headers: { "Content-Type": "application/json" } };
  //   const body = JSON.stringify({ first_name, last_name, email, company_id, department });

  try {
    dispatch({
      type: CONTACT_CREATE_REQUESTED,
    });
    // const res = await axios.post(basePath + "/api/people/addtocomp", body, config);
    const callable = app.functions("europe-west3").httpsCallable("app-api");
    const res = await callable({
      function_name: "companies-add_contact_to_company",
      first_name,
      last_name,
      email,
      company_id,
      department,
    });

    if (res.data.status === "okay") {
      dispatch({
        type: CONTACT_CREATE_SUCCEEDED,
        payload: res.data,
      });
    } else {
      dispatch(setAlertAction(res.data.message, "danger"));
      dispatch({
        type: CONTACT_CREATE_FAILED,
      });
    }
  } catch (err) {
    console.log(err.code);
    dispatch(setAlertAction(err.message, "danger"));

    dispatch({
      type: CONTACT_CREATE_FAILED,
    });
  }
};

export const addContactBatchAction = (contacts, company) => async (dispatch) => {
  //   const config = { headers: { "Content-Type": "application/json" } };
  //   const body = JSON.stringify({ company: company, employees: contacts });

  try {
    dispatch({
      type: CONTACT_CREATE_REQUESTED,
    });
    // await axios.post(basePath + "/api/people/batch", body, config);
    const callable = app.functions("europe-west3").httpsCallable("app-api");
    const res = await callable({ function_name: "contacts-create_bulk_users", company, employees: contacts });

    if (res.data.status === "okay") {
      dispatch(
        setAlertAction(
          `Erfolgreich ${res.data.data.successCount} Kontakte hochgeladen. ${res.data.data.failureCount} Fehler.`,
          "success",
          2500
        )
      );
      if (res.data.data.failureCount) {
        res.data.data.errors.forEach((element) => {
          dispatch(setAlertAction(`Fehler: ${element.error.errorInfo.message}`, "danger", 2500));
        });
      }

      dispatch({
        type: CONTACT_CREATE_SUCCEEDED,
      });
    } else {
      dispatch(setAlertAction("Fehler beim Upload der Kontakte: " + res.data.data.message, "danger"));
      dispatch({
        type: CONTACT_CREATE_FAILED,
      });
    }
  } catch (err) {
    dispatch({
      type: CONTACT_CREATE_FAILED,
    });
  }
};

export const sendResetEmailAction = (email, hasAccountAlready) => async (dispatch) => {
  try {
    const res = await app.functions("europe-west3").httpsCallable("app-api")({
      function_name: "contacts-send_password_reset",
      email,
      has_account_already: hasAccountAlready,
    });
    if (res.data.status === "okay") {
      dispatch(setAlertAction("Sie sollten in Kürze eine Email erhalten", "success"));
    } else {
      if (res.data.message.toString().indexOf("EMAIL_NOT_FOUND") >= 0) {
        dispatch(
          setAlertAction("Es ist kein Nutzer für diese Email angelegt. Kontaktieren Sie bitte den Support", "danger")
        );
      } else {
        dispatch(setAlertAction("Fehler beim Anfordern des Links: " + res.data.message, "danger"));
      }
    }
  } catch (err) {
    dispatch(setAlertAction("Fehler Anfordern des Links: " + err.message, "danger"));
  }
};
