import { app } from "../../../../App";
import { formatToDate } from "../../../utils/format_date";
import {
  ORDERS_LOADING_FAILED,
  ORDERS_LOADING_REQUESTED,
  ORDERS_LOADING_SUCCEEDED,
  ORDERS_RESET,
} from "../action_types";
import axios from "axios";

// Authentificate-action
export const loadOrdersAction = (offset, company_id, sql_access) => async (dispatch) => {
  //   const config = { headers: { "Content-Access-Control-Allow-Origin": "https://zmdesign-8c7c9.web.app" } };
  const batch_size = 500;
  const total_size = 1000;

  if (sql_access) {
    try {
      dispatch({
        type: ORDERS_LOADING_REQUESTED,
      });

      let entries = [];
      for (let x = 0; x < total_size / batch_size; x++) {
        const _res = await app
          .functions("europe-west3")
          .httpsCallable("app-api")({
            function_name: "data-perform_sql_query",
            offset: offset + batch_size * x,
            company_id,
            batch_size,
          })
          .then((r) => JSON.parse(r.data.data));
        entries = [...entries, ..._res];
      }

      const orders = entries.map((order) => ({
        ...order,
        wdatum: order.wdatum ? formatToDate(order.wdatum) : "",
        adatum: order.adatum ? formatToDate(order.adatum) : "",
        termin: order.wdatum ? formatToDate(order.termin) : "",
      }));

      dispatch({
        type: ORDERS_LOADING_SUCCEEDED,
        payload: orders,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ORDERS_LOADING_FAILED,
      });
    }
  }
};
