import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// rest takes in all additional args
const PrivateRoute = ({ component: Component, auth: { isAuthenticated, loading }, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (loading) {
        return <Fragment></Fragment>;
      } else if (!isAuthenticated && !loading) {
        return <Redirect to="/" />;
      } else {
        return <Component {...props}></Component>;
      }
    }}
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const ExtractPropsFromState = (state) => ({
  auth: state.authReducer,
});
export default connect(ExtractPropsFromState)(PrivateRoute);
