import { saveAs } from "file-saver";
import { app } from "../../App";
import img from "../../assets/download_icon.png";

export const ergzipDownload = (doc, company, inTable) => {
  const val = inTable ? doc.value.ergzip : doc.ergzip.ergzip;
  const eintrag = inTable ? doc.value.eintrag : doc.ergzip.eintrag;
  const name = inTable ? doc.value.name : doc.ergzip.name;
  if (val === 1) {
    return (
      <p
        className="link"
        onClick={async () => {
          await app
            .functions("europe-west3")
            .httpsCallable("app-api")({
              function_name: "data-get_ergzip",
              eintrag: eintrag,
              company_sql_id: company.data.sql_id,
            })
            .then((result) => {
              const decoded = JSON.parse(result.data?.data);
              console.log(decoded[0].ergzip);
              const data = decoded[0].ergzip.data;

              if (data != null) {
                const blob = new Blob([new Uint8Array(data)]);

                saveAs(blob, name);
              }
            });
        }}
      >
        <img className="download_icon" src={img} alt=""></img>
      </p>
    );
  } else {
    return "-";
  }
};
