import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAlertAction } from "../../../redux/actions_reducers/alerts/alert_actions";
import { changeSqlIdAction } from "../../../redux/actions_reducers/companies/company_actions";
import { Loader } from "../../loader";

function ChangeSQL(props) {
  const { loading, setAlertAction, changeSqlIdAction } = props;
  const history = useHistory();
  const company = props.location.state;

  //   const [loading, setLoading] = useState(false);
  const [newIdentifier, setNewIdentifier] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    if (newIdentifier.trim() === "" || newIdentifier == null) {
      setAlertAction("Kein neuer Identifier ist ausgewählt", "danger");
    } else if (newIdentifier.trim() === company.data.sql_id) {
      setAlertAction("Der neu angegebene Identifier stimmt mit dem bisherigen überein", "danger");
    } else {
      const response = await changeSqlIdAction(company.id, newIdentifier);

      if (response.status === "okay") {
        history.push("/admin/companies/edit", response.data);
      }
    }
  };

  if (loading) {
    return (
      <Fragment>
        <div>
          <h1 className="large text-primary">MANDANTEN-ID ÄNDERN: {company.data.name}</h1>
        </div>
        {Loader()}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div>
          <h1 className="large text-primary">MANDANTEN-ID ÄNDERN: {company.data.name}</h1>
        </div>
        <form className="form" onSubmit={onSubmit}>
          <input
            type="text"
            value={newIdentifier}
            onChange={(event) => setNewIdentifier(event.target.value)}
            placeholder="Neue Mandanten ID des Kunden"
          ></input>
          <input type="submit" className="light-grey button-light my" value="EINLOGGEN" />
        </form>
      </Fragment>
    );
  }
}

ChangeSQL.propTypes = {
  isAuthenticated: PropTypes.bool,
  uid: PropTypes.string,
};

const extractPropsFromStore = (state) => ({
  loading: state.companiesReducer.loading,
});

// null means that no properties are extracted from the redux-store
export default connect(extractPropsFromStore, { setAlertAction, changeSqlIdAction })(ChangeSQL);
