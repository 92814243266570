import { Fragment } from "react";
import { ergpdfDownload } from "../../../utils/ergpdf_download";
import { ergzipDownload } from "../../../utils/ergzip_download";
import { formatToDate } from "../../../utils/format_date";
import { parseErgpms, renderErgpms } from "../../../utils/parse_ergpms";

export const singleOrderPage = (order, company) => {
  order = {
    ...order,
    ergpdf: { ergpdf: order.ergpdf, name: "MelutecAuftragNr" + order.anummer + ".pdf" },
    ergzip: { ergzip: order.ergzip, name: "MelutecAuftragNr" + order.anummer + ".zip", eintrag: order.eintrag },
  };
  return (
    <Fragment>
      <div className="block">
        <h3>Auftrag</h3>
        <div>
          <h4 className="inline">Auftragsnummer:</h4> {order.anummer}
        </div>
        <div>
          <h4 className="inline">Auftragsdatum:</h4> {formatToDate(order.adatum)}
        </div>
        <div>
          <h4 className="inline">Wareneingang:</h4> {formatToDate(order.wdatum)}
        </div>
        <div>
          <h4 className="inline">Liefertermin:</h4> {formatToDate(order.termin)}
        </div>
        <div>
          <h4 className="inline">Besteller:</h4> {order.besteller}
        </div>
      </div>
      <br></br>
      <div>
        <h3>Daten</h3>
        <div>
          <h4 className="inline">PDF Datei: </h4> <div className="inline">{ergpdfDownload(order, false)}</div>
        </div>
        <div>
          <h4 className="inline">ZIP Datei:</h4> <div className="inline">{ergzipDownload(order, company, false)}</div>
        </div>
        <div>
          <h4 className="inline">Status:</h4> {`${order.fertig} von ${order.gesamt} (${order.prozent}%)`}
        </div>
        <div>
          <h4 className="inline">Ergebnis:</h4>{" "}
          <div className="inline">{renderErgpms(parseErgpms(order.ergpms), false)}</div>
        </div>
      </div>
      <br></br>
      <div>
        <h3>Kunde</h3>
        <div>
          <h4 className="inline">Name:</h4> {order.kname}
        </div>
        <div>
          <h4 className="inline">Kundennummer:</h4> {order.knummer}
        </div>
        <div>
          <h4 className="inline">Ort:</h4> {order.kort + ", " + order.kplz}
        </div>
      </div>
    </Fragment>
  );
};
