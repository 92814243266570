import { combineReducers } from "redux";
import { alertReducer } from "../actions_reducers/alerts/alert_reducer";
import { authReducer } from "../actions_reducers/auth/auth_reducer";
import { ordersReducer } from "../actions_reducers/orders/order_reducer";
import { companiesReducer } from "../actions_reducers/companies/company_reducer";
import { documentsReducer } from "../actions_reducers/documents/documents_reducer";
import { contactsReducer } from "../actions_reducers/contacts/contacts_reducer";

export default combineReducers({
  alertReducer,
  authReducer,
  ordersReducer,
  companiesReducer,
  documentsReducer,
  contactsReducer,
});
