import { saveAs } from "file-saver";
import img from "../../assets/download_icon.png";

export const ergpdfDownload = (doc, inTable) => {
  const data = inTable ? doc.value?.ergpdf?.data : doc?.ergpdf?.ergpdf?.data;
  const name = inTable ? doc.value?.name : doc?.ergpdf?.name;
  if (data != null) {
    const blob = new Blob([new Uint8Array(data)]);
    return (
      <p
        className="link"
        onClick={() => {
          saveAs(blob, name);
        }}
      >
        <img className="download_icon" src={img} alt=""></img>
      </p>
    );
  } else {
    return "-";
  }
};
