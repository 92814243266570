import { SET_ALERT, REMOVE_ALERT } from "../action_types";
import { v4 as uuid } from "uuid";

export const setAlertAction = (msg, alertType, displayTime = 2500) => (dispatch) => {
  const actionId = uuid();

  //   // Sets the alert
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, actionId },
  });

  //   // Removes the alert after a given time
  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: actionId }), displayTime);
};
