import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

export const CustomCreatableSelect = ({ classNameForDiv, options, placeholder, onCreate, onSelect } = {}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "8px",
      fontSize: "1.1rem",
      border: "1px solid grey",
      borderRadius: 0,
    }),
  };

  const CaretDownIcon = () => {
    return <i className="fas fa-caret-down" />;
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const handleChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      onSelect(inputValue.value);
    } else if (actionMeta.action === "create-option") {
      onCreate(inputValue.value);
    }
  };

  return (
    <div className={classNameForDiv}>
      <CreatableSelect
        isClearable
        styles={customStyles}
        components={DropdownIndicator}
        placeholder={placeholder}
        options={options}
        onChange={handleChange}
      />
    </div>
  );
};
//
//
