import { SET_ALERT, REMOVE_ALERT } from "../action_types";

export const alertReducer = function (state = [], givenAction) {
  const { type, payload } = givenAction;

  switch (type) {
    case SET_ALERT:
      return [...state, payload];
    case REMOVE_ALERT:
      return state.filter((alert) => alert.actionId !== payload); // returns all except match
    default:
      return state;
  }
};
