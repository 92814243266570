import firebase from "firebase/app";
import "firebase/storage";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { setAlertAction } from "../../../redux/actions_reducers/alerts/alert_actions";
import { loadDocumentsAction } from "../../../redux/actions_reducers/documents/documents_actions";
import { parseCompaniesToSelect } from "../../../utils/parse_companies_to_select";
import { CustomSelect } from "../../global/select";

function Documents(props) {
  const { loading, companies, companiesResult, documents, setAlertAction, loadDocumentsAction } = props;
  //   const history = useHistory();

  //   useEffect(() => {
  //     loadDocumentsAction();
  //   }, [loadDocumentsAction]);

  //   const [selectedCompany, setselectedCompany] = useState(null);
  const onChange = (e) => {
    // setselectedCompany(e?.value);
    loadDocumentsAction(e?.value?.id, null);
  };

  //
  const [selectedDocument, setselectedDocument] = useState(null);
  //   const onDocumentChange = (e) => setselectedDocument(e?.value);

  //   const [selectedCompany, setselectedCompany] = useState(null);
  //   const onCompanyChange = (e) => {
  //     console.log(e.value);
  //     setselectedCompany(e.value);
  //     // console.log(parseCompaniesToSelect(companies.map((comp) => comp.value)));
  //     console.log("selectedCompany:", selectedCompany);
  //     loadDocumentsAction(selectedCompany.id, null);
  //   };

  const parseDocumentsToSelect = (_documents) =>
    _documents.map((_doc) => {
      return { value: _doc, label: _doc.name };
    });

  const onSubmit = async () => {
    if (selectedDocument == null) {
      setAlertAction("Kein Dokument ausgewählt", "danger");
    } else {
      await firebase
        .storage()
        .ref(selectedDocument.path)
        .delete()
        .then(
          () => {
            window.location.reload();
          },
          () => setAlertAction("Dokument konnte nicht gelöscht werden", "dagner")
        );
    }
  };

  if (loading) {
    return <div>Loading</div>;
  } else if (!loading && companiesResult === "success") {
    return (
      <Fragment>
        <div>
          <h1 className="large text-primary">DOKUMENTE BEARBEITEN</h1>
          <p className="text-bold mt-3">Wählen Sie das Dokument aus, das Sie entfernen möchten</p>
        </div>

        <div className="mb-4">
          <div className="pt-1 pb flex">
            {CustomSelect({
              classNameForDiv: "auto-flex mr",
              options: parseCompaniesToSelect(companies),
              placeholder: "Kunden auswählen",
              onChange: onChange,
            })}
            {CustomSelect({
              classNameForDiv: "auto-flex ml",
              options: parseDocumentsToSelect(documents),
              placeholder: "Dokument auswählen",
              onChange: (option) => setselectedDocument(option?.value),
            })}
          </div>

          <button className="button-danger right50 mb-4" onClick={(e) => onSubmit(e)}>
            Entfernen
          </button>
        </div>
      </Fragment>
    );
  } else {
    return <div></div>;
  }
}

Documents.propTypes = {
  setAlertAction: PropTypes.func.isRequired,
};

const extractPropsFromStore = (state) => ({
  companiesLoading: state.companiesReducer.loading,
  companies: state.companiesReducer.companies,
  companiesResult: state.companiesReducer.companiesResult,
  documentsLoading: state.documentsReducer.documentsLoading,
  documents: state.documentsReducer.documents,
  documentsResult: state.documentsReducer.documentsResult,
});

export default connect(extractPropsFromStore, { setAlertAction, loadDocumentsAction })(Documents);
