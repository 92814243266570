export const FileUploader = ({ classNameForButton, text, hiddenFileInput, onFileSelected, accept } = {}) => {
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  return (
    <div className="my">
      <button className={classNameForButton} onClick={handleClick}>
        {text}
      </button>
      <input type="file" ref={hiddenFileInput} onChange={onFileSelected} accept={accept} style={{ display: "none" }} />
    </div>
  );
};
export default FileUploader;
