export const parseErgpms = (_ergpms) => {
  try {
    const ergpms = JSON.parse(_ergpms.replaceAll("'", '"'));
    let includes = false;
    if (ergpms.length > 0) {
      for (let i in ergpms) {
        const erg = ergpms[i];
        if (erg.includes("NIO")) {
          includes = true;
        } else {
          continue;
        }
      }
      return includes;
    } else {
      return null;
    }
  } catch (e) {
    console.log(typeof _ergpms);
    return null;
  }
};

export const renderErgpms = (input, inTable) => {
  const hasRed = inTable ? input.value : input;

  const color = hasRed ? "red" : "green";
  if (hasRed != null) {
    return (
      <div className="flex">
        <div className={`dot ${color} ${inTable ? "table" : ""}`}></div>
      </div>
    );
  } else {
    return "";
  }
};
