import PropTypes from "prop-types";
import React, { Fragment, useMemo } from "react";
import { connect } from "react-redux";
import { useTable } from "react-table";
import { loadDocumentsAction } from "../../redux/actions_reducers/documents/documents_actions";
import { Loader } from "../loader";
import { Table } from "./sub_components/table";

function UserDocuments(props) {
  const { loading, documents, documentsResult } = props;

  const Columns = [
    {
      Header: "Dokument",
      accessor: "name",
      Cell: (e) => (
        <a className="link" href={e.value.link} target="blank">
          {e.value.name}
        </a>
        // <a
        //   className="link"
        //   onClick={() => {
        //     saveAs(e.value.link, e.value.original_name);
        //   }}
        // >
        //   {e.value.name}
        // </a>
      ),
    },
    {
      Header: "Datum",
      accessor: "date",
    },
    {
      Header: "Größe",
      accessor: "size",
    },
  ];

  const data = documents.map((doc) => ({
    ...doc,
    name: { name: doc.name.split(".")[0], link: doc.url, original_name: doc.name },
  }));

  const columns = useMemo(() => Columns, []);

  const tableInstance = useTable({
    columns,
    data,
  });

  if (loading) {
    return (
      <Fragment>
        <div className="container">
          <h1 className="large">DOKUMENTE</h1>
          {Loader()}
        </div>
      </Fragment>
    );
  } else if (!loading && documentsResult === "success") {
    return (
      <Fragment>
        <div className="custom-container-2">
          <h1 className="large">DOKUMENTE</h1>
          <p>Internetportal zur Übersicht der für Sie freigegebenen Dokumente von MELUTEC</p>
        </div>
        {Table(tableInstance)}
      </Fragment>
    );
    // return (
    //   <Fragment>
    //     <h1 className="large">ÜBERSICHT</h1>
    //     <p className="text-bold">Internetportal zur Übersicht der Aufträge von {company.data.name} an MELUTEC</p>
    //     {Table(tableInstance)}
    //     <button
    //       className="dark-grey button-dark w80"
    //       onClick={() => loadOrdersAction(orders.length, company.id, company.data.sql_access)}
    //     >
    //       Mehr Aufträge laden
    //     </button>
    //   </Fragment>
    // );
  } else {
    return <div></div>;
  }
}

UserDocuments.propTypes = {
  company: PropTypes.object,
  department: PropTypes.string,
  loading: PropTypes.bool,
  documents: PropTypes.array,
  documentsResult: PropTypes.string,
  loadDocumentsAction: PropTypes.func.isRequired,
};

const extractPropsFromStore = (state) => ({
  company: state.authReducer.company,
  department: state.authReducer.department,
  loading: state.documentsReducer.loading,
  documents: state.documentsReducer.documents,
  documentsResult: state.documentsReducer.documentsResult,
});

// null means that no properties are extracted from the redux-store
export default connect(extractPropsFromStore, { loadDocumentsAction })(UserDocuments);
