import React, { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logoutAction } from "../redux/actions_reducers/auth/auth_actions";
import img from "../../assets/logo.png";

function Navbar({ auth: { isAuthenticated, loading, role, company }, logoutAction }) {
  const adminLinks = (
    <ul>
      <li>
        <NavLink className="sm-test" activeClassName="nav-active" to="/admin/companies">
          KUNDEN
        </NavLink>
      </li>
      <li>
        <NavLink className="sm-test" activeClassName="nav-active " to="/admin/documents">
          DOKUMENTE
        </NavLink>
      </li>
      <li>
        <a onClick={logoutAction} href="#!">
          <span className="light-grey button-light button-log-out">AUSLOGGEN</span>
        </a>
      </li>
    </ul>
  );
  const userLinks = (
    <ul>
      {company?.data?.sql_access ? (
        <li>
          <NavLink activeClassName="nav-active" to="/user/dashboard">
            Dashboard
          </NavLink>
        </li>
      ) : (
        <Fragment></Fragment>
      )}
      <li>
        <NavLink activeClassName="nav-active" to="/user/documents">
          Dokumente
        </NavLink>
      </li>
      <li>
        <a onClick={logoutAction} href="#!">
          <span className="light-grey button-light button-log-out">AUSLOGGEN</span>
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link id="navbar-login" to="/login">
          Login
        </Link>
      </li>
    </ul>
  );

  if (isAuthenticated) {
    return (
      <nav className="navbar">
        <ul className="">
          <Link to="/">
            <img className="img-navbar hide-sm" src={img} alt=""></img>
          </Link>
          {/* <div className="inline test">test</div> */}

          <li className="vertical-center hide-sm">
            {isAuthenticated ? (role === "admin" ? "Admin-Bereich" : "Kunden-Bereich") : ""}
          </li>
        </ul>

        {!loading ? (
          <Fragment>{isAuthenticated ? (role === "admin" ? adminLinks : userLinks) : guestLinks}</Fragment>
        ) : null}
      </nav>
    );
  } else {
    return <Fragment></Fragment>;
  }
}

Navbar.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const extractPropsFromStore = (state) => ({
  auth: state.authReducer,
});

export default connect(extractPropsFromStore, { logoutAction })(Navbar);
