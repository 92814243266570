export const Loader = () => (
  <div className="flex my-4">
    <div className="loader"></div>
  </div>
);

export const LargeLoader = () => (
  <div className="flex my-4 ">
    <div className="loader-large"></div>
  </div>
);
